// ------------------------------------------
// LOTTIE ANIMATIONS INIT
// ------------------------------------------

const logoElem = document.getElementById('lottieLogo');
const isPlayingClassName = 'is-playing';
const isInitalizedClassName = 'is-initialized';
const animationPath = 'assets/json/lottie-logo.json';

const logoAnimation = bodymovin.loadAnimation({
  container: logoElem,
  renderer: 'svg',
  loop: false,
  autoplay: false,
  path: animationPath
});

logoElem.addEventListener('click', () => {
  logoAnimation.goToAndPlay(0);
  logoAnimation.addEventListener('complete', () => {
    logoElem.classList.remove(isPlayingClassName);
  })
  logoElem.classList.add(isPlayingClassName, isInitalizedClassName);
})